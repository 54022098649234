<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <div :hidden="true">
      {{ updateKey }}
    </div>
    <Card>
      <template #title>
        <h2 class="text-2xl">
          Products for account {{ accountEmail }}
        </h2>
      </template>
      <template #content>
        <ConfirmDialog />
        <DataTable
          v-model:filters="filters"
          :value="productAds"
          responsive-layout="scroll"
          :scrollable="true"
          scroll-height="800px"
          scroll-direction="both"
          :global-filter-fields="['asin']"
          :rows="20"
          paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows-per-page-options="[10, 20, 50]"
          :paginator="true"
          current-page-report-template="Showing {first} to {last} of {totalRecords}"
        >
          <template #empty>
            No data found.
          </template>

          <template #header>
            <div class="flex justify-content-between">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Search by ASIN..."
                />
              </span>
            </div>
          </template>

          <Column
            field="asin"
            header="ASIN"
            :sortable="true"
            frozen
            class="product-asin product-ad-column"
          />
          <Column
            field="state"
            header="State"
            :sortable="true"
            class="product-state product-ad-column"
          >
            <template #body="slotProps">
              <span
                :class="'campaign-badge status-' + slotProps.data.state"
              >{{
                slotProps.data.state
              }}</span>
            </template>
          </Column>
          <!--       <Column
        field="sku"
        header="SKU"
        :sortable="true"
      /> -->
          <Column
            field="profileName"
            header="Profile"
            :sortable="true"
            class="product-ad-column"
          >
            <template #body="slotProps">
              {{ slotProps.data.profileName + " - " + slotProps.data.profileRegion }}
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import A2CAAuthorizedClient from '../../../api/a2caAuthorizedClient';

export default {
  components: { },
  inject: ['query'],
  props: {
  },
  data() {
    return {
      loading: false,
      updateKey: 0,
      productAds: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },

      },
    };
  },
  async created() {
    this.loading = true;

    const client = new A2CAAuthorizedClient(this.query);
    const response = await client.getAccountProducts(this.$route.params.accountId);
    this.productAds = response.productAds;
    this.accountEmail = response.accountEmail;
    this.loading = false;
  },
  methods: {
  },
};
</script>
<style>
.product-state {
  min-width: 150px;
}
.product-ad-column {
  width: 350px;
}
</style>
<style scoped lang="scss">
@import "../../../assets/styles/dashboard/campaigns.scss";
</style>
